import React from "react";

import { format_metric_value } from "functions";

export default function ToolTipBox({ feature, data_order }) {
    const { data_points } = feature.state;

    if (!data_points) {
        return null;
    }

    const rows = [];
    for (let key of data_order) {
        const value = format_metric_value(key, data_points[key]);
        rows.push(
            <tr key={key}>
                <td>{`${key}: `}</td>
                <td style={{ textAlign: "right" }}>{value}</td>
            </tr>,
        );
    }

    const { name, state, type, county_name } = data_points;
    let region_name = `${name}, ${state}`;
    if (type === "zip") {
        region_name = `${name}, ${county_name}, ${state}`;
    }

    return (
        <div style={{ position: "absolute", top: "0px", left: "0px" }}>
            <div
                style={{
                    background: "white",
                    whiteSpace: "nowrap",
                    margin: ".5rem",
                    padding: "1rem",
                    borderRadius: "4px",
                }}
            >
                <div style={{ fontSize: "14px", paddingBottom: "5px" }}>
                    <b>{region_name}</b>
                </div>
                <table style={{ width: "100%" }}>
                    <tbody>{rows}</tbody>
                </table>
            </div>
        </div>
    );
}
