import React, { useState } from "react";

import Dropdown from "react-bootstrap/Dropdown";

import MapIconButton from "./map_button";
import ReactMapControl from "./map_control";

export function DataControl({
    showCountyLayer,
    setShowCountyLayer,
    showZipLayer,
    setShowZipLayer,
    showDebugBoundaries,
    setShowDebugBoundaries,
}) {
    const [open, setOpen] = useState(false);
    return (
        <ReactMapControl position="top-right">
            <>
                <MapIconButton
                    icon="fa-solid fa-layer-group"
                    title="Data Layers"
                    enableTooltip={!open}
                    onClick={() => setOpen(!open)}
                />
                {open && (
                    <DataPanel
                        showCountyLayer={showCountyLayer}
                        setShowCountyLayer={setShowCountyLayer}
                        showZipLayer={showZipLayer}
                        setShowZipLayer={setShowZipLayer}
                        showDebugBoundaries={showDebugBoundaries}
                        setShowDebugBoundaries={setShowDebugBoundaries}
                    />
                )}
            </>
        </ReactMapControl>
    );
}

function DataPanel({
    showCountyLayer,
    setShowCountyLayer,
    showZipLayer,
    setShowZipLayer,
    showDebugBoundaries,
    setShowDebugBoundaries,
}) {
    const actions = [
        {
            name: "Toggle County Layer",
            onClick: () => setShowCountyLayer(!showCountyLayer),
        },
        { name: "Toggle ZIP Layer", onClick: () => setShowZipLayer(!showZipLayer) },
        {
            name: "Toggle Tile Boundaries",
            onClick: () => setShowDebugBoundaries(!showDebugBoundaries),
        },
    ];

    return (
        <div id="map-layers">
            <Dropdown.Menu show className="border border-dark">
                {actions.map(({ name, onClick }, i) => (
                    <Dropdown.Item key={i} onClick={onClick}>
                        {name}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </div>
    );
}
