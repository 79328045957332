import React, { useContext, useState, useEffect, useRef, useCallback } from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Alert from "react-bootstrap/Alert";

import { fetch, pluralize, formatNumber } from "functions";

import { ScrollSpy, scrollToSection } from "../ui/scrollspy";
import { TitleBarButton } from "../ui/titlebar_button";
import { NavButton } from "../ui/nav_button";

import ParcelViewerContext from "../context";
import MapIconButton from "./map_button";
import ReactMapControl from "./map_control";
import { LIAsyncTypeahead } from "./typeahead";

const SECTION_PARCEL = "parcel";
const SECTION_OWNERSHIP = "ownership";
const SECTION_STRUCTURE = "structure";
const SECTION_OTHER = "other";

export function FilterControl({ open, setOpen }) {
    return (
        <ReactMapControl position="top-right">
            <MapIconButton
                icon="fa-solid fa-filter"
                title="Filter"
                onClick={() => setOpen(!open)}
            />
        </ReactMapControl>
    );
}

export function FilterPanel({ setOpen, searchResult, setSearchResult }) {
    const { county: initialCounty } = useContext(ParcelViewerContext);
    const containerRef = useRef();

    // Form UI state
    const [activeSection, setActiveSection] = useState(SECTION_PARCEL);

    // Form field values
    const [county, setCounty] = useState(initialCounty?.id);
    const [subdivision, setSubdivision] = useState("");
    const [includeZips, setIncludeZips] = useState("");
    const [excludeZips, setExcludeZips] = useState("");
    const [zoning, setZoning] = useState("");
    const [minAcreage, setMinAcreage] = useState("");
    const [maxAcreage, setMaxAcreage] = useState("");
    const [improvement, setImprovement] = useState("");
    const [outOfState, setOutOfState] = useState("");
    const [outOfCounty, setOutOfCounty] = useState("");
    const [outOfZip, setOutOfZip] = useState("");
    const [ownershipLength, setOwnershipLength] = useState("");
    const [schoolDistrict, setSchoolDistrict] = useState("");
    const [buildingSqft, setBuildingSqft] = useState("");
    const [buildingsCount, setBuildingsCount] = useState("");
    const [yearBuilt, setYearBuilt] = useState("");

    // Form field refs
    const countyRef = useRef();

    // Form state
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    // Parcel API response
    const { count } = searchResult || {};

    const searchParcel = async () => {
        setLoading(true);

        try {
            const params = {
                county,
                subdivision,
                zips_include: includeZips,
                zips_exclude: excludeZips,
                zoning,
                acres_min: minAcreage ? minAcreage * 1000 : "",
                acres_max: maxAcreage ? maxAcreage * 1000 : "",
                improvement_percentage_min: improvement,
                improvement_percentage_max: improvement,
                out_of_state_owner: outOfState,
                out_of_county_owner: outOfCounty,
                out_of_zip_owner: outOfZip,
                ownership_length: ownershipLength,
                school_district: schoolDistrict,
                sq_ft: buildingSqft,
                structure_count: buildingsCount,
                year_built: yearBuilt,
                page_size: 50000,
            };
            const queryString = new URLSearchParams(params).toString();
            const searchResult = await fetch(
                `/api/property/parcels/search?${queryString}`,
            );
            console.log("Filter result", searchResult);
            setSearchResult(searchResult);
        } catch (xhr) {
            console.log("Error fetching search results", xhr);
        }

        setLoading(false);
    };

    const validate = () => {
        if (!county) {
            setError("County field is required");
            return false;
        }
        return true;
    };

    const applyFilters = () => {
        reset();
        if (validate()) {
            searchParcel();
        }
    };

    const reset = () => {
        setError();
        setSearchResult();
    };

    const clearFilters = () => {
        setCounty("");
        countyRef.current.clear();
        setSubdivision("");
        setIncludeZips("");
        setExcludeZips("");
        setZoning("");
        setMinAcreage("");
        setMaxAcreage("");
        setImprovement("");
        setOutOfState("");
        setOutOfZip("");
        setOwnershipLength("");
        setSchoolDistrict("");
    };

    return (
        <div id="filter-control" className="d-flex flex-column">
            <div id="filter-control-main" className="d-flex flex-fill">
                <div id="filter-control-nav" className="d-flex flex-column">
                    <NavButton
                        name="Parcel"
                        icon="fa-map-marker"
                        selected={activeSection === SECTION_PARCEL}
                        onClick={() => scrollToSection(containerRef, SECTION_PARCEL)}
                    />
                    <NavButton
                        name="Ownership"
                        icon="fa-user-group"
                        selected={activeSection === SECTION_OWNERSHIP}
                        onClick={() => scrollToSection(containerRef, SECTION_OWNERSHIP)}
                    />
                    <NavButton
                        name="Structure"
                        icon="fa-house"
                        selected={activeSection === SECTION_STRUCTURE}
                        onClick={() => scrollToSection(containerRef, SECTION_STRUCTURE)}
                    />
                    <NavButton
                        name="Other"
                        icon="fa-plus"
                        selected={activeSection === SECTION_OTHER}
                        onClick={() => scrollToSection(containerRef, SECTION_OTHER)}
                    />
                </div>
                <div
                    id="filter-control-content"
                    className="d-flex flex-column flex-fill"
                >
                    <div id="filter-control-header" className="d-flex py-3">
                        <h4 className="flex-fill mb-0">Filter Parcels</h4>
                        <TitleBarButton icon="fa-ellipsis" />
                        <TitleBarButton
                            icon="fa-circle-xmark"
                            onClick={() => setOpen(false)}
                        />
                    </div>
                    <ScrollSpy
                        containerRef={containerRef}
                        onActiveSectionChange={setActiveSection}
                    />
                    <div
                        ref={containerRef}
                        id="filter-control-body"
                        className="flex-fill pe-3"
                    >
                        <SectionHeader section={SECTION_PARCEL} first>
                            Parcel
                        </SectionHeader>

                        {!!error && <Alert variant="danger">{error}</Alert>}

                        <div className="form-group">
                            <Label
                                htmlFor="countyInput"
                                tooltip="The county that the parcel is located in."
                            >
                                County
                            </Label>
                            <CountyFIPSSearch
                                inputRef={countyRef}
                                id="countyInput"
                                onChange={setCounty}
                                defaultInputValue={initialCounty?.name}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="subdivisionSelect"
                                tooltip="The subdivision that the parcel is located in."
                            >
                                Subdivision
                            </Label>
                            <Autocomplete
                                id="subdivisionSelect"
                                county={county}
                                field="SubdivisionName"
                                placeholder="Enter Subdivision..."
                                onChange={setSubdivision}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="includeZips"
                                tooltip="The ZIP codes you want to scope the search down to."
                            >
                                Include ZIPs
                            </Label>
                            <input
                                id="includeZips"
                                type="text"
                                className="form-control"
                                value={includeZips}
                                onChange={(e) => setIncludeZips(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="excludeZips"
                                tooltip="The ZIP codes you want to exclude from the search criteria."
                            >
                                Exclude ZIPs
                            </Label>
                            <input
                                id="excludeZips"
                                type="text"
                                className="form-control"
                                value={excludeZips}
                                onChange={(e) => setExcludeZips(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="zoning"
                                tooltip="The zoning category that county has deemed."
                            >
                                Zoning
                            </Label>
                            <Autocomplete
                                id="zoning"
                                county={county}
                                field="Zoning"
                                placeholder="Enter Zoning..."
                                onChange={setZoning}
                                minLength={2}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="minAcreage"
                                tooltip="The minimum and maximum of acres you want to include in your search. A blank field means you don't want to set a minimum or maximum."
                            >
                                Acreage
                            </Label>
                            <div className="input-group">
                                <input
                                    id="minAcreage"
                                    type="number"
                                    className="form-control"
                                    value={minAcreage}
                                    onChange={(e) => setMinAcreage(e.target.value)}
                                    placeholder="Min"
                                />
                                <input
                                    id="maxAcreage"
                                    type="number"
                                    className="form-control"
                                    value={maxAcreage}
                                    onChange={(e) => setMaxAcreage(e.target.value)}
                                    placeholder="Max"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="improvement"
                                tooltip="How much the overall value of the parcel has increased past just the land itself. Determined by the county accessor."
                            >
                                Improvement %
                            </Label>
                            <input
                                id="improvement"
                                type="number"
                                className="form-control"
                                value={improvement}
                                onChange={(e) => setImprovement(e.target.value)}
                                placeholder="%"
                            />
                        </div>

                        <SectionHeader section={SECTION_OWNERSHIP}>
                            Ownership
                        </SectionHeader>

                        <div className="form-group">
                            <Label
                                htmlFor="outOfState"
                                tooltip="Remove owners that live in the same state as the parcel."
                            >
                                Out Of State Owner
                            </Label>
                            <BooleanSelect
                                id="outOfState"
                                value={outOfState}
                                onChange={setOutOfState}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="outOfCounty"
                                tooltip="Remove owners that live in the same county as the parcel."
                            >
                                Out Of County Owner
                            </Label>
                            <BooleanSelect
                                id="outOfCounty"
                                value={outOfCounty}
                                onChange={setOutOfCounty}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="outOfZip"
                                tooltip="Remove owners that live in the same ZIP as the parcel."
                            >
                                Out Of ZIP Owner
                            </Label>
                            <BooleanSelect
                                id="outOfZip"
                                value={outOfZip}
                                onChange={setOutOfZip}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="ownershipLength"
                                tooltip="Remove owners that haven't owned the parcel for at least a certain amount of months. Putting 1 translates to 1 month, putting 12 translates to 1 year, etc."
                            >
                                Minimum Ownership Length
                            </Label>
                            <input
                                id="ownershipLength"
                                type="number"
                                className="form-control"
                                value={ownershipLength}
                                onChange={(e) => setOwnershipLength(e.target.value)}
                                placeholder="Minimum Months Owned"
                            />
                        </div>

                        <SectionHeader section={SECTION_STRUCTURE}>
                            Structure
                        </SectionHeader>

                        <div className="form-group">
                            <Label
                                htmlFor="buildingSqft"
                                tooltip="The maximum total amount of square feet of structures. Set 0 for no structures."
                            >
                                Total Sqft Feet Of Structures
                            </Label>
                            <input
                                id="buildingSqft"
                                type="number"
                                className="form-control"
                                value={buildingSqft}
                                onChange={(e) => setBuildingSqft(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="buildingsCount"
                                tooltip="The maximum amount of buildings. Set 0 for structures."
                            >
                                Total Structure Count
                            </Label>
                            <input
                                id="buildingsCount"
                                type="number"
                                className="form-control"
                                value={buildingsCount}
                                onChange={(e) => setBuildingsCount(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <Label
                                htmlFor="yearBuilt"
                                tooltip="The year the latest significant construction occurred on the building."
                            >
                                Structure Year Built
                            </Label>
                            <input
                                id="yearBuilt"
                                type="number"
                                className="form-control"
                                value={yearBuilt}
                                onChange={(e) => setYearBuilt(e.target.value)}
                            />
                        </div>

                        <SectionHeader section={SECTION_OTHER}>Other</SectionHeader>

                        <div className="form-group" style={{ marginBottom: 500 }}>
                            <Label
                                htmlFor="schoolDistrict"
                                tooltip="Remove parcels that aren't within the chosen school district(s)"
                            >
                                School District
                            </Label>
                            <Autocomplete
                                id="schoolDistrict"
                                county={county}
                                field="SchoolDistrictName"
                                placeholder="Enter School District Name..."
                                onChange={setSchoolDistrict}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div id="filter-control-footer" className="border-top">
                <div
                    id="filter-control-status"
                    className="bg-gradient-primary text-center"
                >
                    <h5 className="my-3">
                        {count > 0
                            ? `${formatNumber(count)} ${pluralize("Parcel", count)} Found`
                            : count === 0
                              ? "No Parcels Found"
                              : ""}
                    </h5>
                </div>
                <div
                    id="filter-control-actions"
                    className="d-flex flex-wrap justify-content-evenly"
                >
                    <button
                        className="btn btn-sm bg-gradient-secondary w-45"
                        onClick={clearFilters}
                    >
                        Clear Filters
                    </button>
                    <button
                        className="btn btn-sm bg-gradient-dark w-45"
                        onClick={applyFilters}
                    >
                        Apply Filters
                    </button>
                    <button className="btn btn-sm bg-gradient-info w-45">
                        Save List
                    </button>
                    <button className="btn btn-sm bg-gradient-primary w-45">
                        Export List
                    </button>
                </div>
            </div>
        </div>
    );
}

function SectionHeader({ section, children, first }) {
    return (
        <>
            <h5 data-section={section} className={`mb-1 ${first ? "" : "mt-5"}`}>
                {children}
            </h5>
            <hr className="horizontal dark mt-0" />
        </>
    );
}

function Label({ htmlFor, tooltip, children }) {
    return (
        <label htmlFor={htmlFor}>
            {children}{" "}
            {tooltip && (
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>{tooltip}</Tooltip>}
                >
                    <i className="fas fa-circle-question text-secondary" />
                </OverlayTrigger>
            )}
        </label>
    );
}

function CountyFIPSSearch({ id, onChange, inputRef, ...props }) {
    const [options, setOptions] = useState();
    const [loading, setLoading] = useState(false);

    const searchCounty = async (query) => {
        setLoading(true);
        try {
            const result = await fetch(`/api/property/fips_lookup/?name=${query}`);
            setOptions(result?.results);
        } catch (xhr) {
            console.log("Error fetching FIPS lookup", xhr);
        }
        setLoading(false);
    };

    const onSearchResultClicked = (selection) => {
        const option = selection[0];
        if (!option) {
            return;
        }
        onChange(option.id);
    };

    // Pass id to inputProps for htmlFor to work
    const inputProps = { id };

    return (
        <div className="input-group">
            <span className="input-group-text">
                <i className="fas fa-search" />
            </span>
            <LIAsyncTypeahead
                ref={inputRef}
                placeholder="Enter County Name"
                isLoading={loading}
                options={options}
                onSearch={searchCounty}
                onChange={onSearchResultClicked}
                onClear={onChange}
                inputProps={inputProps}
                {...props}
            />
        </div>
    );
}

function Autocomplete({ id, county, field, onChange, inputRef, ...props }) {
    const [options, setOptions] = useState();
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState(true);

    const onSearch = async (query) => {
        setLoading(true);
        setIsValid(true);
        try {
            const params = { county, field, query };
            const queryString = new URLSearchParams(params).toString();
            const result = await fetch(`/api/property/autocomplete/?${queryString}`);
            setOptions(result?.data.map((str) => ({ id: str, label: str })));
        } catch (xhr) {
            setIsValid(false);
            console.log("Error fetching autocomplete lookup", xhr);
        }
        setLoading(false);
    };

    const onSearchResultClicked = (selection) => {
        const option = selection[0];
        if (!option) {
            return;
        }
        onChange(option.id);
    };

    // Pass id to inputProps for htmlFor to work
    const inputProps = { id };

    return (
        <div className="input-group">
            <LIAsyncTypeahead
                ref={inputRef}
                isLoading={loading}
                options={options}
                onSearch={onSearch}
                onChange={onSearchResultClicked}
                onClear={onChange}
                inputProps={inputProps}
                isInvalid={!isValid}
                {...props}
            />
        </div>
    );
}

function BooleanSelect({ id, value, onChange }) {
    return (
        <select
            id={id}
            className="form-control"
            value={value}
            onChange={(e) => onChange(e.target.value)}
        >
            <option value=""></option>
            <option value="True">Yes</option>
            <option value="False">No</option>
        </select>
    );
}
