import React from "react";

export function TitleBarButton({ icon, onClick }) {
    return (
        <button
            className="btn btn-lg btn-outline-secondary border-0 py-1 px-2 mb-0"
            onClick={onClick}
        >
            <i className={`fas ${icon}`} />
        </button>
    );
}
