import React, { useCallback, useContext, useState } from "react";

import Dropdown from "react-bootstrap/Dropdown";

import { MapRef, useMap } from "react-map-gl";
import { Feature } from "geojson";

import { fetch, fetchMapboxFeature, fetchMapboxSuggestions } from "functions";

import type { County, Option, SearchOption, SearchResult, Point } from "../types";
import ParcelViewerContext from "../context";
import MapIconButton from "./map_button";
import ReactMapControl from "./map_control";
import { LIAsyncTypeahead } from "./typeahead";
import type { LIAsyncTypeaheadProps } from "./typeahead";

// Search options
const SEARCH_LOCATION = "Location";
const SEARCH_PARCEL = "Parcel #";
const SEARCH_OWNER = "Owner";

// Mapbox filters
const COUNTY_FILTER = [
    "all",
    ["==", ["get", "level"], 2],
    ["==", ["get", "iso_a2"], "US"],
];
const ZIP_FILTER = [
    "all",
    ["==", ["get", "level"], 1],
    ["==", ["get", "iso_a2"], "US"],
];

interface FeatureTypeMapping {
    identifyLayer: string;
    idField: string[];
    filter?: any;
}

// Map featureType to Mapbox layers
const FEATURE_TYPE_MAPPING: Record<string, FeatureTypeMapping> = {
    district: {
        identifyLayer: "county_identify",
        filter: COUNTY_FILTER,
        idField: ["id"],
    },
    postcode: { identifyLayer: "zip_identify", filter: ZIP_FILTER, idField: ["id"] },
    // Parcel layer must use feature-state ID (properties) as a work workaround
    // because layer filters don't work with "promoteId" (Mapbox bug).
    // The "address" feature type mapping is used to locate parcel features for
    // both the Mapbox Address search and Parcel # search.
    address: { identifyLayer: "parcel_identify", idField: ["get", "id"] },
};

interface SearchControlProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export function SearchControl({ open, setOpen }: SearchControlProps) {
    return (
        <ReactMapControl id="search-control-button" position="top-right">
            <MapIconButton
                icon="fa-solid fa-magnifying-glass"
                title="Parcel Search"
                enableTooltip={!open}
                onClick={() => setOpen(!open)}
            />
        </ReactMapControl>
    );
}

interface SearchBoxProps {
    setActiveParcelID: (parcelID: number) => void;
    setSearchResult: (result: SearchResult) => void;
}

export function SearchBox({ setActiveParcelID, setSearchResult }: SearchBoxProps) {
    const { current: map } = useMap();
    const { setMapFilter, county, setCounty } = useContext(ParcelViewerContext);

    // UI state
    const [searchMode, setSearchMode] = useState(SEARCH_LOCATION);

    // Locate parcel on map and mark it active
    const highlightParcel = useCallback(
        async (point: Point) => {
            setMapFilter();

            const result = await findParcelOnMap(map, point);
            console.log("Map feature", result);

            if (result && result.mapFeature) {
                const { identifyLayer, idField, mapFeature } = result;
                const parcelID = parseInt(`${mapFeature.id}`, 10);
                const filter = ["==", idField, parcelID];
                const inverseFilter = ["!", filter];
                setMapFilter({ identifyLayer, filter, inverseFilter });
                setActiveParcelID(parcelID);
            }
        },
        [map, setMapFilter, setActiveParcelID],
    );

    // Locate feature on map and highlight it by applying shadow filter
    const highlightFeature = useCallback(
        async (feature: Feature) => {
            // Reset filters
            setMapFilter();

            const result = await findFeatureOnMap(map, feature);
            console.log("Map feature", result);

            if (result && result.mapFeature) {
                const { identifyLayer, filter, idField, mapFeature } = result;

                // Filter layer by feature ID
                let newFilter = ["==", idField, mapFeature.id];
                let inverseFilter = ["!", newFilter];

                // Include County/Zip layer filter
                if (filter) {
                    newFilter = ["all", filter, newFilter];
                    inverseFilter = ["all", filter, inverseFilter];
                }

                // Apply map filter for shadow layer
                setMapFilter({ identifyLayer, filter: newFilter, inverseFilter });

                // Mark county as "active" for Parcel # search
                const featureType = feature?.properties?.feature_type;
                if (featureType === "district") {
                    setCounty({
                        // id is the county FIPS code
                        id: mapFeature.properties.code,
                        name: feature.properties.name,
                    });
                }
            }
        },
        [map, setMapFilter, setCounty],
    );

    // Handle GPS "search result" clicked
    const onPointSelected = (point: Point) => {
        setMapFilter();
        map.easeTo({ center: point, zoom: 16, duration: 0 });
    };

    // Handle Parcel # search result clicked
    const onParcelSelected = useCallback(
        (point: Point) => {
            highlightParcel(point);
        },
        [highlightParcel],
    );

    const onOwnerSelected = useCallback(
        (result: SearchResult) => {
            setSearchResult(result);
        },
        [setSearchResult],
    );

    // Handle Mapbox search result clicked
    const onFeatureSelected = useCallback(
        async (feature: Feature) => {
            const featureType = feature?.properties?.feature_type;
            if (featureType === "district") {
                // Handle county features
                highlightFeature(feature);
            } else if (featureType === "postcode") {
                // Handle ZIP features
                highlightFeature(feature);
            } else if (featureType === "address") {
                // Handle address features (assume parcel exists at address)
                const { longitude, latitude } = feature.properties.coordinates;
                const point: Point = [longitude, latitude];
                highlightParcel(point);
            } else {
                console.log("Unknown feature type", featureType);
            }
        },
        [highlightFeature, highlightParcel],
    );

    // Use distinct `key` prop for each MapboxSearch component in order for
    // autoFocus to work properly.
    const searchField =
        searchMode === SEARCH_LOCATION ? (
            <MapboxSearch
                key="search-region"
                featureType="district,postcode,address"
                placeholder="County, ZIP Code, Address, or GPS..."
                onFeatureSelected={onFeatureSelected}
                onPointSelected={onPointSelected}
                onClear={setCounty}
                defaultInputValue={county?.name}
                detectGPS
                autoFocus
            />
        ) : searchMode === SEARCH_PARCEL ? (
            <>
                <MapboxSearch
                    key="search-parcel-county"
                    featureType="district"
                    placeholder="Enter county name"
                    onFeatureSelected={onFeatureSelected}
                    onClear={setCounty}
                    defaultInputValue={county?.name}
                    className="border-radius-0"
                    autoFocus={!county}
                />
                <ParcelSearch
                    county={county}
                    onParcelSelected={onParcelSelected}
                    autoFocus={county}
                />
            </>
        ) : searchMode === SEARCH_OWNER ? (
            <>
                <MapboxSearch
                    key="search-owner-county"
                    featureType="district"
                    placeholder="Enter county name"
                    onFeatureSelected={onFeatureSelected}
                    onClear={setCounty}
                    defaultInputValue={county?.name}
                    className="border-radius-0"
                    autoFocus={!county}
                />
                <OwnerSearch
                    county={county}
                    onOwnerSelected={onOwnerSelected}
                    autoFocus={county}
                />
            </>
        ) : null;

    return (
        <div id="search-control">
            <SearchDropdown
                selectedValue={searchMode}
                onChange={(value) => setSearchMode(value)}
            />
            {searchField}
        </div>
    );
}

// Find Mapbox feature on map using administrative and parcel layers.
async function findFeatureOnMap(map: MapRef, feature: Feature) {
    const { longitude, latitude } = feature.properties.coordinates;

    // County/Zip features return bbox. Address features return point.
    if (feature.properties.bbox) {
        map.fitBounds(feature.properties.bbox, {
            padding: 40,
            // duration has a nonzero value to give tiles time to load before
            // calling queryRenderedFeatures. Otherwise, the projected point
            // may reference off-screen pixel values.
            duration: 250,
        });
    } else {
        (map as any).easeTo({ center: [longitude, latitude], zoom: 16, duration: 0 });
    }

    // Wait for map animation to stop, and for tiles to load, in order for
    // queryRenderedFeatures to work.
    await asyncMapEvent(map, "idle");

    const mapping: FeatureTypeMapping =
        FEATURE_TYPE_MAPPING[feature.properties.feature_type];
    if (!mapping) {
        console.log("feature type not supported", feature);
        return;
    }

    const { identifyLayer, filter, idField } = mapping;
    const point = map.project([longitude, latitude]);
    const features = map.queryRenderedFeatures(point, { layers: [identifyLayer] });

    const mapFeature = features && features[0];

    return { identifyLayer, filter, idField, mapFeature };
}

// Find parcel on map from its coordinates.
async function findParcelOnMap(map: MapRef, point: Point) {
    (map as any).easeTo({ center: point, zoom: 16, duration: 0 });

    // Wait for map animation to stop, and for tiles to load, in order for
    // queryRenderedFeatures to work.
    await asyncMapEvent(map, "idle");

    const { identifyLayer, idField } = FEATURE_TYPE_MAPPING["address"];
    const screenPoint = map.project(point);
    const features = map.queryRenderedFeatures(screenPoint, {
        layers: [identifyLayer],
    });

    const mapFeature = features && features[0];

    return { identifyLayer, idField, mapFeature };
}

function parseCoordinates(coordinateString: string): Point {
    // Parse coordinates in order of more-to-less specific pattern matching
    return (
        parseDegreesMinutesSeconds(coordinateString) ||
        parseDecimalCoordinates(coordinateString)
    );
}

function parseDecimalCoordinates(coordinateString: string): Point {
    const [latStr, lngStr] = coordinateString.split(",").map((str) => str.trim());
    const lat = parseFloat(latStr);
    const lng = parseFloat(lngStr);
    const point: Point = [lng, lat];
    return !isNaN(lat) && !isNaN(lng) ? point : null;
}

function parseDegreesMinutesSeconds(coordinateString: string): Point {
    function parseSingleCoordinate(coord: string): number {
        if (!coord) return null;
        const regex = /(\d+)° (\d+)' (\d+\.\d+)" ([NSEW])/;
        const match = coord.match(regex);
        if (!match) return null;

        const [, degrees, minutes, seconds, direction] = match;
        let decimalDegrees =
            parseInt(degrees) + parseInt(minutes) / 60 + parseFloat(seconds) / 3600;

        if (direction === "S" || direction === "W") {
            decimalDegrees = -decimalDegrees;
        }

        return isNaN(decimalDegrees) ? null : decimalDegrees;
    }

    const [latStr, lngStr] = coordinateString.split(", ");
    const lat = parseSingleCoordinate(latStr);
    const lng = parseSingleCoordinate(lngStr);
    const point: Point = [lng, lat];

    return lat !== null && lng !== null ? point : null;
}

function formatCoordinates(point: Point) {
    function format(coord: number, type: "lat" | "lng") {
        const absolute = Math.abs(coord);
        const degrees = Math.floor(absolute);
        const minutes = Math.floor((absolute - degrees) * 60);
        const seconds = ((absolute - degrees) * 60 - minutes) * 60;
        const direction =
            coord >= 0 ? (type === "lat" ? "N" : "E") : type === "lat" ? "S" : "W";
        return `${degrees}° ${minutes}' ${seconds.toFixed(2)}" ${direction}`;
    }
    const [lng, lat] = point;
    return `${format(lat, "lat")}, ${format(lng, "lng")}`;
}

interface ParcelSearchProps extends LIAsyncTypeaheadProps {
    county: County;
    onParcelSelected: (point: Point) => void;
}

function ParcelSearch({ county, onParcelSelected, ...props }: ParcelSearchProps) {
    const [options, setOptions] = useState<Option[]>();
    const [loading, setLoading] = useState(false);

    const searchParcel = async (query: string) => {
        setLoading(true);
        try {
            const params = {
                county: county.id,
                property_id: query,
                page_size: "5",
            };
            const queryString = new URLSearchParams(params).toString();
            const searchResult: SearchResult = await fetch(
                `/api/property/parcels/?${queryString}`,
            );
            const options = searchResult.parcels.map(({ PropertyID, point }) => ({
                label: `${PropertyID}`,
                value: point,
            }));
            setOptions(options);
        } catch (xhr) {
            console.log(xhr);
        }
        setLoading(false);
    };

    const onSearchResultClicked = (selected: Option[]) => {
        const option = selected[0] as SearchOption;
        if (option) {
            const point = option.value as Point;
            onParcelSelected(point);
        }
    };

    return (
        <LIAsyncTypeahead
            placeholder="Enter Parcel #"
            isLoading={loading}
            options={options}
            onSearch={searchParcel}
            onChange={onSearchResultClicked}
            disabled={!county}
            {...props}
        />
    );
}

interface OwnerSearchProps extends LIAsyncTypeaheadProps {
    county: County;
    onOwnerSelected: (result: SearchResult) => void;
}

function OwnerSearch({ county, onOwnerSelected, ...props }: OwnerSearchProps) {
    const [options, setOptions] = useState<Option[]>();
    const [loading, setLoading] = useState(false);

    const searchOwner = async (query: string) => {
        setLoading(true);
        try {
            const params = {
                county: county.id,
                owner_trigram: query,
            };
            const queryString = new URLSearchParams(params).toString();
            const searchResult = await fetch(
                `/api/property/parcels/owner_search/?${queryString}`,
            );
            setOptions(searchResult);
        } catch (xhr) {
            console.log(xhr);
        }
        setLoading(false);
    };

    const searchOwnerParcels = async (value: string) => {
        setLoading(true);
        try {
            const params: Record<string, any> = {
                county: county.id,
                bbox: 1,
            };
            const owners = value.split(",");
            owners.forEach((owner, i) => {
                params[`owner${i + 1}_exact`] = owner;
            });
            const queryString = new URLSearchParams(params).toString();
            const searchResult = await fetch(
                `/api/property/parcels/search/?${queryString}`,
            );
            await onOwnerSelected(searchResult);
        } catch (xhr) {
            console.log(xhr);
        }
        setLoading(false);
    };

    const onSearchResultClicked = async (selected: Option[]) => {
        const option = selected[0] as SearchOption;
        if (option) {
            const value = option.value as string;
            await searchOwnerParcels(value);
        }
    };

    return (
        <LIAsyncTypeahead
            style={{ flexBasis: "25%" }}
            placeholder="Owner Name"
            isLoading={loading}
            options={options}
            onSearch={searchOwner}
            onChange={onSearchResultClicked}
            disabled={!county}
            {...props}
        />
    );
}

interface MapboxSearchProps extends LIAsyncTypeaheadProps {
    featureType: string;
    onFeatureSelected: (value: Feature) => void;
    onPointSelected?: (point: Point) => void;
    detectGPS?: boolean;
}

function MapboxSearch({
    featureType,
    onFeatureSelected,
    onPointSelected,
    detectGPS = false,
    ...props
}: MapboxSearchProps) {
    const [options, setOptions] = useState<Option[]>();
    const [loading, setLoading] = useState(false);

    const onSearch = async (query: string) => {
        setLoading(true);

        const point = parseCoordinates(query);
        if (point) {
            setOptions([
                {
                    label: formatCoordinates(point),
                    value: point,
                },
            ]);
        } else {
            const suggestions = await searchMapbox(query, featureType);
            const options = suggestions?.map((item) => ({
                id: item.mapbox_id,
                label: item.name,
                subLabel: item.place_formatted,
            }));
            setOptions(options);
        }

        setLoading(false);
    };

    const onSearchResultClicked = useCallback(
        async (selected: Option[]) => {
            const option = selected[0] as SearchOption;
            if (!option) {
                return;
            }
            if (option.value) {
                const point = option.value as Point;
                onPointSelected(point);
                return;
            }
            const featureCollection = await fetchMapboxFeature(option.id);
            const feature = featureCollection.features[0];
            console.log("Mapbox search feature", feature);
            onFeatureSelected(feature);
        },
        [onPointSelected, onFeatureSelected],
    );

    // const tryDetectGPS = (text: string) => {
    //     const point = parseCoordinates(text);
    //     if (point) {
    //         console.log("GPS detected!", point);
    //     }
    // };
    // onInputChange={detectGPS ? tryDetectGPS:null}

    return (
        <LIAsyncTypeahead
            key={featureType}
            isLoading={loading}
            options={options}
            onSearch={onSearch}
            onChange={onSearchResultClicked}
            {...props}
        />
    );
}

async function searchMapbox(query: string, featureType: string) {
    try {
        const data = await fetchMapboxSuggestions(query, {
            types: featureType,
        });
        return data?.suggestions || [];
    } catch (xhr) {
        console.log(xhr);
    }
}

interface SearchDropdownProps {
    selectedValue: string;
    onChange: (value: string) => void;
}

function SearchDropdown({ selectedValue, onChange }: SearchDropdownProps) {
    const options = [SEARCH_LOCATION, SEARCH_PARCEL, SEARCH_OWNER];
    return (
        <Dropdown>
            <Dropdown.Toggle
                variant="secondary-outline"
                id="search-mode-dropdown-button"
                className="mb-0"
            >
                {selectedValue}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {options.map((value, i) => (
                    <Dropdown.Item key={i} onClick={() => onChange(value)}>
                        {value}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

// Wrap map event handler in promise to use with async/await.
function asyncMapEvent(map: MapRef, eventName: string): Promise<void> {
    return new Promise<void>((resolve) => {
        map.once(eventName, () => resolve());
    });
}
