import { InternalLayout } from "components";

import {
    AdminDashboard,
    AdminUserDetail,
    Campaigns,
    ContactUs,
    Dashboard,
    DataExportIndex,
    FeatureIdea,
    LandScrubbingCreate,
    LandScrubbingDashboard,
    LandScrubbingResult,
    MarketSelectionCourse,
    ParcelViewer,
    PhotoEditor,
    RealtorReportCreate,
    RealtorReportList,
    RealtorReportView,
    SavedFilters,
    Settings,
    SkipTraceCreate,
    SkipTraceDetail,
    SkipTraceList,
} from "pages";

const routes = [
    {
        path: "/map",
        element: <ParcelViewer />,
    },
    {
        path: "/home",
        element: <InternalLayout />,
        children: [
            {
                path: "",
                element: <Dashboard key="market_research" title="Market Research" />,
            },
            {
                path: "market_research",
                element: <Dashboard key="market_research" title="Market Research" />,
            },
            {
                path: "my_markets",
                element: (
                    <Dashboard
                        key="my_markets"
                        title="My Markets"
                        viewingMyMarketsPage
                    />
                ),
            },
            {
                path: "saved_filters",
                element: <SavedFilters />,
            },
            {
                path: "campaigns",
                element: <Campaigns />,
            },
            {
                path: "land_scrubbing",
                children: [
                    {
                        index: true,
                        element: <LandScrubbingDashboard />,
                    },
                    {
                        path: "new",
                        element: <LandScrubbingCreate />,
                    },
                    {
                        path: "results/:id",
                        element: <LandScrubbingResult />,
                    },
                ],
            },
            {
                path: "skip_tracing",
                children: [
                    {
                        index: true,
                        element: <SkipTraceList />,
                    },
                    {
                        path: "new",
                        element: <SkipTraceCreate />,
                    },
                    {
                        path: ":id",
                        element: <SkipTraceDetail />,
                    },
                ],
            },
            {
                path: "realtor_report",
                children: [
                    {
                        index: true,
                        element: <RealtorReportList />,
                    },
                    {
                        path: "new",
                        element: <RealtorReportCreate />,
                    },
                    {
                        path: ":state/:county",
                        element: <RealtorReportView />,
                    },
                    {
                        path: ":state",
                        element: <RealtorReportView />,
                    },
                ],
            },
            {
                path: "photo_editor",
                element: <PhotoEditor />,
            },
            {
                path: "market_selection_course",
                element: <MarketSelectionCourse />,
            },
            {
                path: "settings",
                element: <Settings />,
            },
            {
                path: "contact_us",
                element: <ContactUs />,
            },
            {
                path: "feature_idea",
                element: <FeatureIdea />,
            },
            {
                path: "admin",
                children: [
                    {
                        index: true,
                        element: <AdminDashboard />,
                    },
                    {
                        path: "user/:id",
                        element: <AdminUserDetail />,
                    },
                ],
            },
            {
                path: "data_export",
                element: <DataExportIndex />,
            },
        ],
    },
];

export default routes;
