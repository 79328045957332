import React from "react";

export function NavButton({ name, icon, selected, onClick }) {
    const className = `btn ${selected ? "btn-primary" : "btn-outline-secondary"} mb-0 px-0 border-0`;
    return (
        <button className={className} onClick={onClick}>
            <i className={`fas ${icon}`} />
            <div className="text-xxs">{name}</div>
        </button>
    );
}
