import React, { useState, useEffect, useCallback } from "react";

// ScrollSpy tracks the scroll position of a container element and determines
// which section (based on the `data-section` attribute) is currently closest
// to the top of the container. It calls `onActiveSectionChange` (with the
// `data-section` attribute value) whenever the active section changes.
export function ScrollSpy({ onActiveSectionChange, containerRef }) {
    const [activeSection, setActiveSection] = useState();

    const handleScroll = useCallback(() => {
        const container = containerRef.current;
        if (!container) {
            return;
        }

        const childrenArray = Array.from(container.querySelectorAll("[data-section]"));
        const scrollTop = container.scrollTop;

        let closestSection = null;
        let closestDifference = Infinity;

        for (let i = 0; i < childrenArray.length; i++) {
            const child = childrenArray[i];
            const offsetTop = child.offsetTop;
            const difference = Math.abs(offsetTop - scrollTop);

            if (difference < closestDifference) {
                closestDifference = difference;
                closestSection = child;
            }
        }

        if (closestSection) {
            const newActiveSection = closestSection.getAttribute("data-section");
            if (newActiveSection !== activeSection) {
                setActiveSection(newActiveSection);
                onActiveSectionChange(newActiveSection);
            }
        }
    }, [containerRef, activeSection, setActiveSection, onActiveSectionChange]);

    useEffect(() => {
        if (!containerRef.current) {
            return;
        }

        const container = containerRef.current;
        container.addEventListener("scroll", handleScroll);
        handleScroll(); // Initial active section determination

        return () => {
            container.removeEventListener("scroll", handleScroll);
        };
    }, [containerRef, handleScroll]);

    return null;
}

export function scrollToSection(containerRef, sectionID) {
    const container = containerRef.current;
    const targetSection = container.querySelector(`[data-section="${sectionID}"]`);
    if (targetSection) {
        container.scrollTo({
            top: targetSection.offsetTop - container.offsetTop,
            behavior: "smooth",
        });
    }
}
