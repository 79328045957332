import { useControl, useMap } from "react-map-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { useEffect } from "react";
import { fetch } from "functions";

export default function DrawControl({ position }) {
    const { current: map } = useMap();
    const draw = useControl(
        () =>
            new MapboxDraw({
                styles: [
                    // ACTIVE (being drawn)
                    // line stroke
                    {
                        id: "gl-draw-line",
                        type: "line",
                        filter: ["all", ["==", "$type", "LineString"]],
                        layout: {
                            "line-cap": "round",
                            "line-join": "round",
                        },
                        paint: {
                            "line-color": "#D20C0C",
                            "line-dasharray": [0.2, 2],
                            "line-width": 2,
                        },
                    },
                    // polygon fill
                    {
                        id: "gl-draw-polygon-fill",
                        type: "fill",
                        filter: ["all", ["==", "$type", "Polygon"]],
                        paint: {
                            "fill-color": "#D20C0C",
                            "fill-outline-color": "#D20C0C",
                            "fill-opacity": 0.1,
                        },
                    },
                    // polygon mid points
                    {
                        id: "gl-draw-polygon-midpoint",
                        type: "circle",
                        filter: [
                            "all",
                            ["==", "$type", "Point"],
                            ["==", "meta", "midpoint"],
                        ],
                        paint: {
                            "circle-radius": 3,
                            "circle-color": "#fbb03b",
                        },
                    },
                    // polygon outline stroke
                    // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
                    {
                        id: "gl-draw-polygon-stroke-active",
                        type: "line",
                        filter: ["all", ["==", "$type", "Polygon"]],
                        layout: {
                            "line-cap": "round",
                            "line-join": "round",
                        },
                        paint: {
                            "line-color": "#D20C0C",
                            "line-dasharray": [0.2, 2],
                            "line-width": 2,
                        },
                    },
                    // vertex point halos
                    {
                        id: "gl-draw-polygon-and-line-vertex-halo-active",
                        type: "circle",
                        filter: [
                            "all",
                            ["==", "meta", "vertex"],
                            ["==", "$type", "Point"],
                        ],
                        paint: {
                            "circle-radius": 5,
                            "circle-color": "#FFF",
                        },
                    },
                    // vertex points
                    {
                        id: "gl-draw-polygon-and-line-vertex-active",
                        type: "circle",
                        filter: [
                            "all",
                            ["==", "meta", "vertex"],
                            ["==", "$type", "Point"],
                        ],
                        paint: {
                            "circle-radius": 3,
                            "circle-color": "#D20C0C",
                        },
                    },
                ],
                controls: {
                    point: false,
                    line_string: false,
                    polygon: true,
                    trash: true,
                    combine_features: false,
                    uncombine_features: false,
                },
                // defaultMode: 'draw_polygon',
            }),
        {
            position,
        },
    );

    useEffect(() => {
        if (map) {
            map.on("draw.modechange", (e) => {
                console.log("modechange: ", e);
            });
            map.on("draw.create", async (e) => {
                const polygon = e.features[0];
                const geoJson = JSON.stringify(polygon.geometry);
                const results = await fetch(
                    "/api/property/parcels/search/?geom_intersects=" + geoJson,
                );
                console.log(results.count);
            });
        }
        return () => {
            if (map) {
                map.off("draw.create");
            }
        };
    }, [map]);
    return null;
}
