import React, { useEffect, useState } from "react";

import { PinturaEditor } from "@pqina/react-pintura";
import { getEditorDefaults, createMarkupEditorToolbar } from "@pqina/pintura";

import "@pqina/pintura/pintura.css";

import Swal from "sweetalert2";

import { FilesDragAndDrop } from "components";

const editorDefaults = getEditorDefaults();

export default function PhotoEditor() {
    const [inlineResult, setInlineResult] = useState();
    const [file, setFile] = useState();

    const onUpload = (files) => {
        setFile(files[0]);
    };

    // Show modal with result image output
    useEffect(() => {
        if (inlineResult) {
            Swal.fire({
                imageUrl: inlineResult,
                animation: false,
                confirmButtonText: "Download",
                showCancelButton: true,
                preConfirm: () => {
                    const filename = prefixWithDate(file.name);
                    downloadImage(inlineResult, filename);
                },
                customClass: {
                    image: "photo-editor-image",
                },
            });
        }
    }, [file, inlineResult]);

    if (!file) {
        return (
            <Layout>
                <div className="my-5 w-md-75 mx-auto">
                    <FilesDragAndDrop
                        onUpload={onUpload}
                        count={1}
                        containerStyles={{
                            width: "100%",
                            border: "2px dashed #cccccc",
                            padding: "20px",
                        }}
                        openDialogOnClick
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <p>Drag &amp; Drop</p>
                            <p>--- or ---</p>
                            <p>Click to Select File</p>
                        </div>
                    </FilesDragAndDrop>
                </div>
            </Layout>
        );
    }

    // Define the custom LI Filter
    const filterFunctions = {
        colorPopLow: () => [
            1.58, -0.32, -0.03, 0.0, -0.01, -0.09, 1.35, -0.03, 0.0, -0.01, -0.09,
            -0.32, 1.64, 0.0, -0.01, 0.0, 0.0, 0.0, 1.0, 0.0,
        ],
        colorPopHigh: () => [
            2.98, -1.66, -0.16, 0.0, -0.0, -0.49, 1.82, -0.16, 0.0, -0.0, -0.49, -1.66,
            3.31, 0.0, -0.0, 0.0, 0.0, 0.0, 1.0, 0.0,
        ],
    };

    // Define the filter option
    const filterOptions = [
        ["Low Pop", [["colorPopLow", () => "Low Pop"]]],
        ["High Pop", [["colorPopHigh", () => "High Pop"]]],
    ];

    return (
        <Layout>
            <div style={{ height: "70vh" }}>
                <PinturaEditor
                    {...editorDefaults}
                    src={file}
                    utils={["crop", "filter", "finetune", "annotate", "fill", "resize"]}
                    enableDropImage
                    enablePasteImage
                    enableBrowseImage
                    cropSelectPresetOptions={[
                        [undefined, "Custom"],
                        [1, "Square"],
                        [4 / 3, "Landscape"],
                        [3 / 4, "Portrait"],
                    ]}
                    cropEnableButtonFlipVertical
                    cropEnableInfoIndicator
                    cropEnableButtonToggleCropLimit
                    cropAutoCenterImageSelectionTimeout={1000}
                    enableSelectToolToAddShape
                    enableMoveTool
                    enableTapToAddText
                    markupEditorSnapThreshold={2}
                    markupEditorToolbar={createMarkupEditorToolbar([
                        "move",
                        "sharpie",
                        "eraser",
                        "text",
                        "path",
                        "line",
                        "arrow",
                        "rectangle",
                        "ellipse",
                    ])}
                    onProcess={(res) => setInlineResult(URL.createObjectURL(res.dest))}
                    filterFunctions={filterFunctions}
                    filterOptions={filterOptions}
                />
            </div>
        </Layout>
    );
}

function Layout({ children }) {
    return (
        <div className="card">
            <div className="card-header pb-0">
                <h5 className="mb-0">Listing Photo Editor</h5>
            </div>
            <div className="card-body">{children}</div>
        </div>
    );
}

function downloadImage(imageURL, filename) {
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href); // Clean up memory
}

function prefixWithDate(str) {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");
    const prefix = `MODIFIED-${year}-${month}-${day}-`;
    return prefix + str;
}
