import React, { Component, useState, useEffect } from "react";

import Board from "@asseinfo/react-kanban";
import "@asseinfo/react-kanban/dist/styles.css";

import { UserContext } from "context";
import { DataPreferences } from "components";
import { Form, TextArea, Modal, Select, Loading } from "library";
import {
    ajax_wrapper,
    formatDate,
    format_metric_label,
    format_metric_value,
    get_metric_display_group,
    isErrorResponse,
} from "functions";

import { METRIC_KEYS, RANGE_PAIRS, ACRE_RANGES } from "constants";

const CAMPAIGN_COLS = ["Backlog", "In Progress", "Prepared", "Sent"];

function CampaignForm({ id, title, data, refresh_markets, hide_notes }) {
    if (data.notes.length === 0) {
        return (
            <Form
                submit_url="/api/notes/"
                defaults={{ market_id: id }}
                submit_success={(value) => {
                    data.notes = [value];
                    hide_notes();
                }}
                submit_button_float={{ float: "right" }}
                delete_url={`/api/markets/${id}/`}
                delete_text={"Delete Campaign"}
                delete_callback={refresh_markets}
            >
                <TextArea name="text" label="New Note" autosize={true} />
            </Form>
        );
    }
    return (
        <>
            {data.notes.map((item, i) => (
                <Form
                    key={i}
                    submit_url={`/api/notes/${item.id}/`}
                    defaults={{ text: item.text }}
                    submit_on_enter={false}
                    submit_success={(value) => {
                        data.notes = [value];
                        hide_notes();
                    }}
                    submit_button_float={{ float: "right" }}
                    delete_url={`/api/markets/${id}/`}
                    delete_text={"Delete Campaign"}
                    delete_callback={refresh_markets}
                >
                    <h6 className="mb-0">{title}</h6>
                    <hr className="horizontal dark my-3"></hr>
                    <TextArea label="Edit Note.." name="text" autosize={true} />
                    <div
                        className="form-group"
                        style={{ fontSize: "12px", textAlign: "center" }}
                    >
                        <div>{`Note Added: ${formatDate(item.created_at)}`}</div>
                        <div>{`Last Edited: ${formatDate(item.updated_at)}`}</div>
                    </div>
                </Form>
            ))}
        </>
    );
}

class KanbanCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show_notes: false,
        };
    }

    render() {
        let campaign_form = (
            <CampaignForm
                id={this.props.card.id}
                title={this.props.card.title}
                data={this.props.card.data}
                hide_notes={() => this.setState({ show_notes: false })}
                refresh_markets={this.props.refresh_markets}
            />
        );

        let modal = null;
        if (this.state.show_notes) {
            modal = (
                <Modal
                    key={this.props.card.id}
                    show={true}
                    on_hide={() => this.setState({ show_notes: false })}
                >
                    {campaign_form}
                </Modal>
            );
        }

        return (
            <div>
                {modal}
                <div
                    className="react-kanban-card p-2"
                    onClick={() =>
                        this.setState({ show_notes: !this.state.show_notes })
                    }
                >
                    <div className="react-kanban-card-title">
                        <h4 className="m-0">{this.props.card.title}</h4>
                    </div>
                    <div className="react-kanban-card-description">
                        <KanbanDescription
                            data={this.props.card.data}
                            acre_range={this.props.card.data.acre_range}
                            showDataPoints={this.props.showDataPoints}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

// Dynamically update Kanban Board height so that it always fills the remaining
// vertical space on the page.
const CalculateKanbanHeightComponent = () => {
    const [height, setHeight] = useState(0);

    useEffect(() => {
        const calculateHeight = () => {
            const target = document.querySelector("#kanban-board-card");
            if (!target) {
                return;
            }

            const viewportHeight = window.innerHeight;
            const targetRect = target.getBoundingClientRect();
            const targetYOffset = targetRect.top + window.pageYOffset;

            const newHeight = viewportHeight - targetYOffset;
            setHeight(newHeight);
        };

        calculateHeight();
        window.addEventListener("resize", calculateHeight);

        return () => {
            window.removeEventListener("resize", calculateHeight);
        };
    }, []);

    return (
        <style>{`.react-kanban-board { height: calc(${height}px - 1.5rem); } .react-kanban-board > div[data-rbd-droppable-id="board-droppable"] { height: calc(${height}px - 2.7rem); }`}</style>
    );
};

const MAX_COLOR_BOT = [23, 173, 55];
const MAX_COLOR_TOP = [152, 236, 45];

const MIN_COLOR_BOT = [234, 6, 6];
const MIN_COLOR_TOP = [255, 102, 124];

function get_color_by_percent(percent) {
    let bot_diff = [
        MAX_COLOR_BOT[0] - MIN_COLOR_BOT[0],
        MAX_COLOR_BOT[1] - MIN_COLOR_BOT[1],
        MAX_COLOR_BOT[2] - MIN_COLOR_BOT[2],
    ];

    let top_diff = [
        MAX_COLOR_TOP[0] - MIN_COLOR_TOP[0],
        MAX_COLOR_TOP[1] - MIN_COLOR_TOP[1],
        MAX_COLOR_TOP[2] - MIN_COLOR_TOP[2],
    ];

    let bot_color = [
        MIN_COLOR_BOT[0] + bot_diff[0] * percent,
        MIN_COLOR_BOT[1] + bot_diff[1] * percent,
        MIN_COLOR_BOT[2] + bot_diff[2] * percent,
    ];

    let top_color = [
        MIN_COLOR_TOP[0] + top_diff[0] * percent,
        MIN_COLOR_TOP[1] + top_diff[1] * percent,
        MIN_COLOR_TOP[2] + top_diff[2] * percent,
    ];

    return `linear-gradient(310deg, rgb(${bot_color[0]}, ${bot_color[1]}, ${bot_color[2]}) 0%, rgb(${top_color[0]}, ${top_color[1]}, ${top_color[2]}) 100%)`;
}

class KanbanDescription extends Component {
    static contextType = UserContext;

    render() {
        const { user } = this.context;

        const region = this.props.data["region"];
        const regionStats = (region.stats || []).find(
            (stat) => stat.type === this.props.acre_range,
        );
        const stats = regionStats?.data;

        const groupedMetrics = {};

        if (stats && this.props.showDataPoints) {
            for (let key of user.data_point_preferences) {
                const groupName = get_metric_display_group(key);
                if (!groupedMetrics[groupName]) {
                    groupedMetrics[groupName] = {};
                }
                groupedMetrics[groupName][key] = stats[key];
            }
        }

        let note_color = "rgba(103, 116, 142, .5)";
        if (this.props.data["notes"].length > 0) {
            note_color = "rgba(103, 116, 142, 1)";
        }

        return (
            <div className="mt-2">
                {Object.entries(groupedMetrics).map(([groupName, metrics]) => (
                    <div key={groupName} className="metric-group mb-3">
                        <div className="mb-1 text-bold text-xxs">{groupName}</div>
                        {Object.entries(metrics).map(([key, value]) => (
                            <div
                                key={key}
                                className="mt-1 me-1 badge badge-md bg-gradient-info"
                            >
                                {format_metric_label(key)}:{" "}
                                {format_metric_value(key, value)}
                            </div>
                        ))}
                    </div>
                ))}
                <div style={{ float: "right", cursor: "pointer" }}>
                    <i className="fas fa-sticky-note" style={{ color: note_color }} />
                </div>
                <div style={{ fontSize: "12px", textAlign: "left" }}>
                    Added {formatDate(this.props.data.created_at)}
                </div>
            </div>
        );
    }
}

export default class Campaigns extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            error: "",
            loaded: false,
            markets: [],
            market_timestamp: Date.now(),

            // RANGE_PAIRS[0][1]
            acre_range: "0-9.5k sqft",

            showDataPrefModal: false,
            showDataPoints: true,
        };

        this.refresh_markets = this.refresh_markets.bind(this);
        this.handle_drag = this.handle_drag.bind(this);
    }

    componentDidMount() {
        this.refresh_markets();
    }

    refresh_markets() {
        this.setState(
            {
                loaded: false,
            },
            function () {
                ajax_wrapper("GET", "/api/markets/", {}, (value) => {
                    if (isErrorResponse(value)) {
                        return;
                    }
                    this.setState({
                        markets: value,
                        loaded: true,
                        market_timestamp: Date.now(),
                    });
                });
            }.bind(this),
        );
    }

    handle_drag(board, card, prev, next) {
        if (prev["fromColumnId"] != next["toColumnId"]) {
            ajax_wrapper("POST", `/api/markets/${card["id"]}/`, {
                status: CAMPAIGN_COLS[next["toColumnId"]],
            });
        }

        if (prev["fromPosition"] != next["toPosition"]) {
            let board_state = [];
            for (let item of board["columns"]) {
                let column = [];
                for (let card of item["cards"]) {
                    column.push(card["id"]);
                }
                board_state.push(column);
            }
            ajax_wrapper("POST", `/update_campaign_order/`, {
                board_state: board_state,
            });
        }
    }

    render() {
        const { user, setUser } = this.context;

        let columns = {};
        for (let item of CAMPAIGN_COLS) {
            columns[item] = [];
        }

        for (let item of this.state.markets) {
            item["acre_range"] = this.state.acre_range;
            let title = `${item["region"]["name"]}, ${item["region"]["state"]}`;
            if (item["region"]["type"] == "zip") {
                title = (
                    <div>
                        <div>{item["region"]["name"]}</div>
                        <div>{`${item["region"]["county_name"]}, ${item["region"]["state"]}`}</div>
                    </div>
                );
            }
            columns[item["status"]].push({
                id: item["id"],
                title: title,
                data: item,
            });
        }

        let board = {
            columns: [],
        };
        for (let key in columns) {
            board["columns"].push({
                id: CAMPAIGN_COLS.indexOf(key),
                title: key.toUpperCase(),
                cards: columns[key],
            });
        }

        let kanban = null;
        if (this.state.loaded) {
            kanban = (
                <Board
                    key={this.state.market_timestamp}
                    initialBoard={board}
                    renderCard={(card, { removeCard, dragging }) => (
                        <KanbanCard
                            key={card.id}
                            card={card}
                            refresh_markets={this.refresh_markets}
                            showDataPoints={this.state.showDataPoints}
                        />
                    )}
                    onCardDragEnd={this.handle_drag}
                    disableColumnDrag={true}
                />
            );
        }

        let field_options = [];
        for (let key of METRIC_KEYS) {
            field_options.push({
                text: `${key}`,
                value: `${key}`,
            });
        }

        return (
            <Loading loaded={this.state.loaded}>
                <div className="card mb-4">
                    <div className="card-body">
                        <div className="row gy-2">
                            <CampaignFilterCol title="Acreage Range">
                                <Select
                                    className="mb-0"
                                    options={RANGE_PAIRS.map(([_, __, key]) => {
                                        return {
                                            text: ACRE_RANGES[key],
                                            value: key,
                                        };
                                    })}
                                    name="acre_range"
                                    value={this.state.acre_range || ""}
                                    set_form_state={(value) =>
                                        this.setState({
                                            acre_range: value["acre_range"],
                                        })
                                    }
                                    no_blank_option={true}
                                />
                            </CampaignFilterCol>
                            <CampaignFilterCol title="Card Data Points Preferences">
                                <button
                                    className="btn btn-outline btn-secondary mb-0"
                                    onClick={(e) =>
                                        this.setState({
                                            showDataPrefModal: true,
                                        })
                                    }
                                >
                                    Modify
                                    <i
                                        className="fa fa-cog fixed-plugin-button-nav cursor-pointer ms-2"
                                        aria-hidden="true"
                                    ></i>
                                </button>
                                {this.state.showDataPrefModal && (
                                    <Modal
                                        show={true}
                                        width={800}
                                        on_hide={() =>
                                            this.setState({
                                                showDataPrefModal: false,
                                            })
                                        }
                                    >
                                        <div>
                                            <h5>Card Data Points Preferences</h5>
                                        </div>
                                        <DataPreferences
                                            user={user}
                                            data_options={field_options}
                                            callback={(newUser) => {
                                                setUser(newUser);
                                                this.setState({
                                                    showDataPrefModal: false,
                                                });
                                            }}
                                        />
                                    </Modal>
                                )}
                            </CampaignFilterCol>
                            <CampaignFilterCol title="Data Points">
                                <button
                                    className="btn btn-outline btn-secondary mb-0"
                                    onClick={(e) =>
                                        this.setState({
                                            showDataPoints: !this.state.showDataPoints,
                                        })
                                    }
                                >
                                    {this.state.showDataPoints ? "Hide" : "Show"}
                                    <i
                                        className={`fa fa-eye${this.state.showDataPoints ? "-slash" : ""} fixed-plugin-button-nav cursor-pointer ms-2`}
                                        aria-hidden="true"
                                    ></i>
                                </button>
                            </CampaignFilterCol>
                        </div>
                    </div>
                </div>
                <div id="kanban-board-card" className="card">
                    <div className="card-body p-0">{kanban}</div>
                </div>
                <CalculateKanbanHeightComponent />
            </Loading>
        );
    }
}

function CampaignFilterCol({ title, children }) {
    return (
        <div className="col-12 col-md-4 d-flex flex-column align-items-stretch">
            <h6>{title}</h6>
            {children}
        </div>
    );
}
