import React from "react";

import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";

// Mapbox Plugins
import RulerControl from "@mapbox-controls/ruler";
import "@mapbox-controls/ruler/src/index.css";

import { default as MapboxInspectControl } from "@mapbox-controls/inspect";
import "@mapbox-controls/inspect/src/index.css";

import { API_URL } from "settings";
import { MAPRIGHT_STYLES } from "constants";
import { Image } from "library";

// https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/
// https://github.com/mapbox/mapbox-react-examples/tree/master/data-overlay

const MARIGHT_SOURCES = [
    "wetlands",
    "floodplain_1",
    "floodplain_2",
    "contour_lines_1",
    "contour_lines_2",
    "contour_lines_3",
    "contour_lines_4",
    "contour_lines_5",
    "contour_lines_6",
    "contour_lines_7",
    "contour_lines_8",
    "contour_lines_9",
    "contour_lines_10",
    "contour_lines_11",
    "contour_lines_12",
    "contour_lines_13",
    "contour_lines_14",
];

export default class ParcelMap extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            zoom: 15,
            map_object: null,
            features_selected: [],

            wetlands_visible: true,
            flood_visible: true,
            contour_visible: false,
        };

        this.mapContainer = React.createRef();

        this.handle_map_load = this.handle_map_load.bind(this);
        this.source_loaded_on_map = this.source_loaded_on_map.bind(this);
        this.highlight_parcel = this.highlight_parcel.bind(this);
    }

    componentDidMount() {
        const { zoom } = this.state;
        const { lng, lat } = this.props;
        const map = new mapboxgl.Map({
            projection: "mercator",
            container: this.mapContainer.current,
            style: "mapbox://styles/kmlandinsights/clyvxfp7m005001px4kv01z02",
            center: [lng, lat],
            zoom: zoom,
            minZoom: 12,
            maxZoom: 17,
        });

        map.on("load", this.handle_map_load);

        map.on("move", () => {
            this.setState({
                zoom: map.getZoom().toFixed(2),
            });
        });

        this.setState({
            map_object: map,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lng != this.props.lng || prevProps.lat != this.props.lat) {
            this.state.map_object.jumpTo({
                center: [this.props.lng, this.props.lat],
            });
            setTimeout(this.highlight_parcel, 100);
        }
        if (prevProps.parcel_quality != this.props.parcel_quality) {
            this.highlight_parcel();
        }
    }

    handle_map_load() {
        const map = this.state.map_object;

        map.on("sourcedata", this.source_loaded_on_map);

        map.addSource("mapbox-dem", {
            type: "raster-dem",
            url: "mapbox://mapbox.mapbox-terrain-dem-v1",
            tileSize: 512,
            maxzoom: 14,
        });

        // add the DEM source as a terrain layer with exaggerated height
        map.setTerrain({ source: "mapbox-dem", exaggeration: 1.0 });

        map.addSource("parcels", {
            type: "vector",
            tiles: [`${API_URL}/tiles/parcels/{z}/{x}/{y}.mvt`],
            minzoom: 14,
            maxzoom: 17,
            promoteId: { parcels: "robust_id" },
        });

        // Wetlands and flood zone layers
        this.add_mapright_sources();

        // Transparent polygon "fill" layer to support highlight_parcel
        var parcelsFill = {
            id: "parcels-fill",
            source: "parcels",
            "source-layer": "parcels",
            type: "fill",
            paint: {
                "fill-outline-color": "transparent",
                "fill-color": "transparent",
            },
        };
        map.addLayer(parcelsFill);

        // Parcel boundary layer
        var parcelsLine = {
            id: "parcels-line",
            source: "parcels",
            "source-layer": "parcels",
            type: "line",
            paint: {
                "line-width": 3,
                "line-color": [
                    "case",
                    ["==", ["feature-state", "selected"], "good"],
                    "#00ff00",
                    ["==", ["feature-state", "selected"], "bad"],
                    "#ff0000",
                    ["==", ["feature-state", "selected"], "highlight"],
                    "#7928ca",
                    ["==", ["feature-state", "selected"], "unknown"],
                    "#ffffff",
                    "rgba(255, 167, 72, 0.5)",
                ],
            },
        };
        map.addLayer(parcelsLine);

        map.loadImage("/static/images/mapright/LightBlue.png", (err, image) => {
            map.addImage("wetlands27", image);
        });
        map.loadImage("/static/images/mapright/DarkBlue.png", (err, image) => {
            map.addImage("500Flood", image);
        });
        map.loadImage("/static/images/mapright/DarkBlue.png", (err, image) => {
            map.addImage("100Flood", image);
        });
        map.loadImage("/static/images/mapright/DarkBlue.png", (err, image) => {
            map.addImage("Floodway7", image);
        });

        // Add ruler control to the map
        map.addControl(
            new RulerControl({
                units: "feet", // Set units to feet
                labelFormat: (n) => `${n.toFixed(2)} ft`, // Customize label format to show feet
            }),
            "bottom-right",
        );

        // Add layer inspector control
        if (this.props.showInspectControl) {
            map.addControl(new MapboxInspectControl(), "bottom-right");
        }

        this.setState({
            loaded: true,
        });
    }

    add_mapright_sources() {
        let map = this.state.map_object;

        for (let name of MARIGHT_SOURCES) {
            var layers = MAPRIGHT_STYLES[name]["layers"];

            map.addSource(name, MAPRIGHT_STYLES[name]["sources"]["composite"]);

            for (let layer of layers) {
                if (layer.id == "background") {
                    continue;
                }

                if (name.includes("floodplain") && layer.id.includes("500")) {
                    continue;
                }

                if (layer.source == "composite") {
                    layer.source = name;
                }

                map.addLayer(layer);

                let visible = "visible";
                if (name.includes("contour")) {
                    visible = this.state.contour_visible ? "visible" : "none";
                }
                map.setLayoutProperty(layer.id, "visibility", visible);
            }
        }
    }

    source_loaded_on_map(e) {
        let map = this.state.map_object;

        if (e.sourceId === "parcels" && e.isSourceLoaded) {
            map.off("sourcedata", this.source_loaded_on_map);

            this.highlight_parcel();
        }
    }

    highlight_parcel() {
        let map = this.state.map_object;

        if (!this.mapContainer.current) {
            setTimeout(this.highlight_parcel, 100);
            return false;
        }

        if (!map.getLayer("parcels-fill")) {
            setTimeout(this.highlight_parcel, 100);
            return false;
        }

        const point = map.project([this.props.lng, this.props.lat]);
        const features = map.queryRenderedFeatures(point, {
            layers: ["parcels-fill"],
        });

        if (features.length > 0) {
            let features_selected = this.state.features_selected;
            // Un-highlight any currently highlighted feature(s).
            if (features_selected && features_selected.length) {
                for (var i = 0; i < features_selected.length; i++) {
                    map.setFeatureState(
                        {
                            source: "parcels",
                            sourceLayer: "parcels",
                            id: features_selected[i].id,
                        },
                        { selected: null },
                    );
                }
            }

            for (let feature of features) {
                map.setFeatureState(
                    {
                        source: "parcels",
                        sourceLayer: "parcels",
                        id: feature.id,
                    },
                    {
                        selected: this.props.parcel_quality
                            ? this.props.parcel_quality
                            : "bad",
                    },
                );
            }

            this.setState({ features_selected: features });
        } else {
            setTimeout(this.highlight_parcel, 100);
        }
    }

    toggle_layer(layer) {
        let map = this.state.map_object;
        let flag_name = `${layer}_visible`;
        let layer_flag = this.state[flag_name];
        layer_flag = !layer_flag;

        if (!map) {
            return false;
        }

        for (let name of MARIGHT_SOURCES) {
            if (!name.includes(layer)) {
                continue;
            }

            var layers = MAPRIGHT_STYLES[name]["layers"];

            for (let layer of layers) {
                if (!map.getLayer(layer["id"])) {
                    continue;
                }

                if (layer_flag) {
                    map.setLayoutProperty(layer["id"], "visibility", "visible");
                } else {
                    map.setLayoutProperty(layer["id"], "visibility", "none");
                }
            }
        }

        let new_state = {};
        new_state[flag_name] = layer_flag;
        this.setState(new_state);
    }

    render() {
        return (
            <div style={this.props.container_style}>
                <div className="custom-map-controls righthand">
                    <button
                        onClick={() => this.toggle_layer("wetlands")}
                        className="btn btn-outline-secondary btn-icon-only custom-map-visiblity"
                        style={{ fontSize: "16px" }}
                    >
                        <img className="noun-icon" src="/static/images/wetland.svg" />
                        {this.state.wetlands_visible ? null : (
                            <div className="strike-through" />
                        )}
                    </button>
                    <button
                        onClick={() => this.toggle_layer("flood")}
                        className="btn btn-outline-secondary btn-icon-only custom-map-visiblity"
                        style={{ fontSize: "16px" }}
                    >
                        <img className="noun-icon" src="/static/images/flood.svg" />
                        {this.state.flood_visible ? null : (
                            <div className="strike-through" />
                        )}
                    </button>
                    <button
                        onClick={() => this.toggle_layer("contour")}
                        className="btn btn-outline-secondary btn-icon-only custom-map-visiblity"
                        style={{ fontSize: "16px" }}
                    >
                        <img className="noun-icon" src="/static/images/contour.svg" />
                        {this.state.contour_visible ? null : (
                            <div className="strike-through" />
                        )}
                    </button>
                </div>

                {/*<div className="map-data-sidebar">
                    Longitude: {this.state.lng} | Latitude: {this.state.lat} | Zoom: {this.state.zoom}
                </div> */}

                {/* Mapbox map */}
                <div
                    ref={this.mapContainer}
                    className="map-container"
                    style={{ height: "575px" }} // Set the height here
                />
            </div>
        );
    }
}
